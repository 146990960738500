<template>
<div>
  <div class="container-fluid">
    <PageTitle />

    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-btn-mass="true"
        :no-multi-select="true"
      > 
        <template #filters>
          <b-col md=3>
            <VSelect 
              v-model="filter.office" 
              placeholder="-- Semua Kantor --"
              :options="mrKantor" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
          <b-col md=3>
            <VSelect 
              v-model="filter.kategori" 
              placeholder="-- Semua Kategori --"
              :options="mrKategori" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #mo_name="data">
          {{data.scope.value || '-'}}
        </template>
        <template #mp_type="data">
          {{data.scope.value == 'korporat' ? 'Risiko Korporat' : 'Mitigasi'}}
        </template>
        <template #mp_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item @click="doDelete(data.scope.index, data.scope.item)">Hapus</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Kategori <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.mp_type" 
                    placeholder="-- Pilih Satu --"
                    :options="mrKategori" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Kategori" 
                    v-model="row.mp_type" 
                    :rules="mrValidation.mp_type" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <template v-if="row.mp_type == 'mitigasi'">
              <b-row>
                <b-col md=3>
                  <b-form-group>
                    <template #label>
                      Kantor <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mp_office_id" 
                      placeholder="-- Pilih Satu --"
                      :options="mrKantor" 
                      :reduce="v=>v.value" 
                    />
                    <VValidate 
                      name="Master Kantor" 
                      v-model="row.mp_office_id" 
                      rules="required" 
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="MrUnitKerja.length" md=3>
                  <b-form-group>
                    <template #label>
                      Unit Kerja <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mp_unit_kerja" 
                      placeholder="-- Pilih Satu --"
                      :options="MrUnitKerja" 
                      :reduce="v=>v.value" 
                    />
                    <VValidate 
                      name="Master Kantor" 
                      v-model="row.mp_unit_kerja" 
                      rules="required" 
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
            <b-row>

              <b-col md=8>
                <b-form-group>
                  <template #label>
                    Nama Jabatan <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.mp_description"
                  />
                  <VValidate 
                    name="Nama Jabatan" 
                    v-model="row.mp_description" 
                    :rules="mrValidation.mp_description" 
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
let _ = global._
export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'mp_id',
      statusKey:'mp_is_active',
      fields:[
        { key:'number', label:'#' },
        { key:'mp_code', label:'Jabatan ID' },
        { key:'mp_type', label:'Kategori', is_custom: true },
        { key:'mo_name', label:'Nama Kantor', is_custom: true },
        { key:'mp_description', label:'Nama Jabatan' },
        { key:'mp_is_active', label:'Status', is_custom:true },
        { key:'aksi', is_custom:true,style:{width:'170px'} },
      ],
      mrIndukCode:[],
      mrBranchLevel:[],
      mrKantor: [],
      mrKategori: []
    }
  },
  computed:{
    MrUnitKerja(){
      if(!this.row.mp_office_id) return []
      const mp = _.filter(this.mrKantor, (v)=>{
        return v.value==this.row.mp_office_id
      })
      if(mp.length){
        return mp[0]['unitKerja']
      }else{
        return []
      }
    }
  },
  mounted(){
    this.apiGet()
    if(this.isAdd) this.row = {}
  },
  watch:{
    $route(){
      this.apiGet()

      if(this.isAdd) this.row = {}
    },
    'row.mp_office_id'(){
      if(!this.MrUnitKerja.length){
        this.$set(this.row, 'mp_unit_kerja', null)
      }
    }
  }
}
</script>